import React from 'react';
import * as styles from './Footer.module.css';

interface FooterLinkItem {
  name: string;
  link: string;
}

interface FooterProps {
  items: FooterLinkItem[];
  copyright: string;
}

const Footer: React.FC<FooterProps> = ({ items, copyright }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.link} className={styles.link}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>
      <p>
        {currentYear} &copy; {copyright} All Right Reserved.
      </p>
    </footer>
  );
};

export default Footer;
