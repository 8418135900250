import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from '../components/Footer1/Footer';
import Headers from '../components/Header102/Header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  // サイトのメタデータから会社名を抽出
  const companyName = data.site.siteMetadata.companyName;

  return (
    <div>
      <Headers
        logoImage="/corporate/logo.svg"
        title={companyName}
        items={[
          {
            id: 1,
            name: 'コラム',
            link: '/pages',
            dropdown: false,
          },
          {
            id: 3,
            name: 'お問い合わせ',
            dropdown: false,
            link: '/contact',
          },
        ]}
      />
      {children}
      <Footer
        items={[
          { name: 'お問い合わせ', link: '/contact' },
          { name: 'プライバシーポリシー', link: '/privacy' },
        ]}
        copyright={companyName}
      />
    </div>
  );
};

export default Layout;
